import { gql } from "@apollo/client";

export const GET_QUERY_DATA = (year: string) => {
  return gql`
    query getQueryData {
      irctc_query(
        where: { Month: { _ilike: "%-${year}" } }
        order_by: { id: desc }
      ) {
        id
        Month
        total_queries
        daily_avg_queries
        english
        gujarati
        hindi
      }
    }
  `;
};
