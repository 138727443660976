import { useGetSessionData } from "../../hooks/sessionReport.hook";
import { SessionDataColumns } from "../../utils/table.utils";
import MultiPickerTable from "../common/multiPickerTable";

const SessionReport = () => {
  const {
    data,
    loading,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    setReload,
  } = useGetSessionData();

  return (
    <MultiPickerTable
      data={data}
      loading={loading}
      startTime={startTime}
      endTime={endTime}
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      columns={SessionDataColumns}
      setReload={setReload}
    />
  );
};

export default SessionReport;
