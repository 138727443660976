import { json2csv } from "json-2-csv";
import moment from "moment";

export const errorTypes = [
  {
    name: "Unable to perform",
    query: ["Unable"],
  },
  {
    name: "Time out",
    query: ["Timeout"],
  },
  {
    name: "This site is currently unavailable",
    query: ["This site is currently"],
  },
  {
    name: "You are allowed only 1 booking",
    query: ["You are allowed"],
  },
  {
    name: "No seats available",
    query: ["No seats"],
  },
  {
    name: "Bookings are not allowed at this time",
    query: ["are NOT ALLOWED"],
  },
  {
    name: "You are permitted only 2 tatkal tickets",
    query: ["permitted only 2"],
  },
  {
    name: "Monthly quota exhausted",
    query: ["month"],
  },
  {
    name: "Server error",
    query: ["SERVER ERROR"],
  },
  {
    name: "General booking not allowed before x",
    query: ["General booking not allowed"],
  },
  {
    name: "Preference mismatch",
    query: ["134971682", "atleast"],
  },
];

export const steps: any = {
  "ade4a7db-d819-417d-832a-259307fd94c7,1": "Book Ticket Query",
  "ade4a7db-d819-417d-832a-259307fd94c7,2": "Source Station Selection",
  "ade4a7db-d819-417d-832a-259307fd94c7,3": "Destination Station Selection",
  "ade4a7db-d819-417d-832a-259307fd94c7,4": "Journey Date Selection",
  "ade4a7db-d819-417d-832a-259307fd94c7,5": "Journey Quota Selection",
  "ade4a7db-d819-417d-832a-259307fd94c7,8": "Train Selected",
  "ade4a7db-d819-417d-832a-259307fd94c7,10": "Filled Passenger Details",
  "ade4a7db-d819-417d-832a-259307fd94c7,11": "Reviewed Booking Details",
  "ade4a7db-d819-417d-832a-259307fd94c7,12": "IRCTC OTP Sent",
  "ade4a7db-d819-417d-832a-259307fd94c7,13": "IRCTC OTP Verification",
  "ade4a7db-d819-417d-832a-259307fd94c7,14": "Payment Successful",
  "ade4a7db-d819-417d-832a-259307fd94c7,15": "Booking Successful",
};

export const getFormattedTime = (ms: number) => {
  return moment(ms).add(-5.5, "hours").format("YYYY-MM-DD HH:mm:ss.0");
};

export const getInterval = (interval: string) => {
  switch (interval) {
    case "hour": {
      return {
        start: moment(Date.now()).add(5.5, "hour").add(-1, "hour").valueOf(),
        end: moment(Date.now()).add(5.5, "hour").valueOf(),
      };
    }
    case "today": {
      return {
        start: moment(
          moment(Date.now()).format("YYYY-MM-DD 00:00:00"),
          "YYYY-MM-DD HH:mm:ss"
        )
          .add(5.5, "hour")
          .valueOf(),
        end: moment(
          moment(Date.now()).add(24, "hours").format("YYYY-MM-DD 00:00:00"),
          "YYYY-MM-DD HH:mm:ss"
        )
          .add(5.5, "hour")
          .valueOf(),
      };
    }
    case "day": {
      return {
        start: moment(Date.now()).add(5.5, "hour").add(-1, "day").valueOf(),
        end: moment(Date.now()).add(5.5, "hour").valueOf(),
      };
    }
    case "week": {
      return {
        start: moment(Date.now()).add(5.5, "hour").add(-7, "days").valueOf(),
        end: moment(Date.now()).add(5.5, "hour").valueOf(),
      };
    }
    case "fortnight": {
      return {
        start: moment(Date.now()).add(5.5, "hour").add(-14, "days").valueOf(),
        end: moment(Date.now()).add(5.5, "hour").valueOf(),
      };
    }
    case "month": {
      return {
        start: moment(Date.now()).add(5.5, "hour").add(-1, "month").valueOf(),
        end: moment(Date.now()).add(5.5, "hour").valueOf(),
      };
    }
  }
};

export const triggerDownload = (data: any[]) => {
  const parsed = JSON.parse(JSON.stringify(data)).map((elem: any) => {
    delete elem.__typename;

    return elem;
  });

  const result = json2csv(parsed);

  const blob = new Blob([result], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "data.csv");

  link.click();
};

export const reduceAmount = (acc: number, elem: any) => {
  return acc + parseFloat(elem.txn_amount);
};
