/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { apoloClient } from "../utils/apollo.utils";
import { GET_QUERY_DATA } from "../graphql/queryData.query";

const useGetQueryData = () => {
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [year, setYear] = useState(new Date().getFullYear());

  const getData = async () => {
    const _data = await apoloClient.query({
      query: GET_QUERY_DATA(year.toString()),
      fetchPolicy: reload ? "network-only" : "cache-first",
    });

    setData(_data.data.irctc_query);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [year]);

  return { loading, data, year, setYear, setReload };
};

export { useGetQueryData };
