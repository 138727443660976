import { gql } from "@apollo/client";

export const GET_RECON_DATA = (date: string) => {
  return gql`
    query getReconData {
        irctc_payment_status(where: {
            txn_date: {
                _like: "${date}%"
            }
        }) {
            order_id
            txn_amount
            result_msg
        }
    }
  `;
};
