/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { apoloClient } from "../utils/apollo.utils";
import { GET_SESSION_DATA } from "../graphql/sessionData.query";

const useGetSessionData = () => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const getSessionData = async () => {
    setLoading(true);

    const data = await apoloClient.query({
      query: GET_SESSION_DATA(
        moment(startTime).format("YYYY-MM-DD"),
        moment(endTime).format("YYYY-MM-DD")
      ),
      fetchPolicy: reload ? "network-only" : "cache-first",
    });

    const _data = [...data.data.irctc_session_data].sort(
      (a: any, b: any) =>
        moment(b.date, "YYYY-MM-DD").valueOf() -
        moment(a.date, "YYYY-MM-DD").valueOf()
    );

    setData(_data);

    setLoading(false);
    setReload(false);
  };

  useEffect(() => {
    setData(() => []);
    getSessionData();
  }, [startTime, endTime]);

  useEffect(() => {
    if (reload) {
      setData(() => []);
      getSessionData();
    }
  }, [reload]);

  return {
    data,
    loading,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    setReload,
  };
};

export { useGetSessionData };
