/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { apoloClient } from "../utils/apollo.utils";
import { GET_RECON_DATA } from "../graphql/recon.query";
import { GET_BOOKINGS_FOR_ID } from "../graphql/booking.query";
import { GET_REFUNDS_BY_ID } from "../graphql/refund.query";
import dayjs from "dayjs";
import { reduceAmount } from "../utils/misc.utils";

const useGetReconData = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<any>(
    dayjs().add(-1, "day").format("YYYY-MM-DD 00:00:00.0")
  );
  const [data, setData] = useState<any[]>([]);
  const [commulativeData, setCommulativeData] = useState<any[]>([]);
  const [reload, setReload] = useState(false);

  const getData = async (trigger: any) => {
    setLoading(true);
    const formattedDate = moment(date).format("YYYY-MM-DD");

    const _data = await apoloClient.query({
      query: GET_RECON_DATA(formattedDate),
      fetchPolicy: reload ? "network-only" : "cache-first",
    });

    const boookings = await apoloClient.query({
      query: GET_BOOKINGS_FOR_ID(
        _data.data.irctc_payment_status.map((elem: any) => elem.order_id)
      ),
    });

    const refunds = await apoloClient.query({
      query: GET_REFUNDS_BY_ID(
        _data.data.irctc_payment_status.map((elem: any) => elem.order_id)
      ),
    });

    if (trigger.clear) {
      return;
    }

    setData(
      _data.data.irctc_payment_status.map((elem: any) => ({
        ...elem,
        booking_status: boookings.data.irctc_Booking.find(
          (e: any) => e.clientTransactionId === elem.order_id
        )
          ? "Ticket Booked"
          : "Not Booked",
        refund_status:
          refunds.data.irctc_refund_status.find(
            (e: any) => e.order_id === elem.order_id
          )?.result_status ?? "No Refund",
      }))
    );
    const commulative: any = {
      total_txn_amount: _data.data.irctc_payment_status
        .filter((elem: any) => elem.result_msg === "Txn Success")
        .reduce(reduceAmount, 0)
        .toFixed(4),
      txn_amount_with_booking: _data.data.irctc_payment_status
        .filter((elem: any) =>
          boookings.data.irctc_Booking.find(
            (e: any) => e.clientTransactionId === elem.order_id
          )
        )
        .reduce(reduceAmount, 0)
        .toFixed(4),
      total_refund_amount: _data.data.irctc_payment_status
        .filter((elem: any) =>
          refunds.data.irctc_refund_status.find(
            (e: any) =>
              e.order_id === elem.order_id &&
              e.reference_id.startsWith("FULLREFUNDID_")
          )
        )
        .reduce(reduceAmount, 0)
        .toFixed(4),
    };

    const difference =
      commulative.total_txn_amount -
      (commulative.txn_amount_with_booking + commulative.total_refund_amount);

    commulative.status = difference ? "UNRECONCILED" : "RECONCILED";

    commulative.total_refund_amount = difference
      ? `${commulative.total_refund_amount} (Few Refunds In-Process)`
      : commulative.total_refund_amount;

    setCommulativeData([commulative]);

    setLoading(false);
    setReload(false);
  };

  useEffect(() => {
    const trigger = { clear: false };
    if (date !== "") {
      setData(() => []);
      setCommulativeData(() => []);
      getData(trigger);
    }

    return () => {
      trigger.clear = true;
    };
  }, [date]);

  useEffect(() => {
    const trigger = { clear: false };
    if (reload) {
      setData(() => []);
      setCommulativeData(() => []);
      getData(trigger);
    }
    return () => {
      trigger.clear = true;
    };
  }, [reload]);

  return {
    loading,
    date,
    data,
    commulativeData,
    setDate,
    setReload,
  };
};

export { useGetReconData };
