import { useQuery } from "@apollo/client";
import { ParameterType } from "../../types/parameters.types";
import { useEffect } from "react";
import { triggerDownload } from "../../utils/misc.utils";
import { CSV_QUERY, DataKey, TableName } from "../../utils/table.utils";
import moment from "moment";

const CSVTool = (props: {
  tableName: TableName;
  parameters: ParameterType;
  toggle: Function;
}) => {
  const { data } = useQuery(CSV_QUERY[props.tableName](props.parameters));

  useEffect(() => {
    if (data) {
      props.toggle(false);
      triggerDownload(
        data[DataKey[props.tableName]].map((elem: any) => {
          if (props.tableName !== TableName.BOOKING) return elem;
          const bookingDate = elem.bookingDate
            ? moment(parseInt(elem.bookingDate))
                .add(-5.5, "hours")
                .format("YYYY-MM-DD hh:mm A")
            : undefined;
          const journeyDate = elem.journeyDate
            ? moment(parseInt(elem.journeyDate))
                .add(-5.5, "hours")
                .format("YYYY-MM-DD")
            : undefined;

          return {
            ...elem,
            bookingDate,
            journeyDate,
            PNR: elem.booking ? elem.booking.PNR : elem.PNR,
            payment_mode: elem.payments?.payment_mode ?? "",
            card_scheme: elem.payments?.card_scheme ?? "",
            txn_amount: elem.payments.txn_amount ?? "",
          };
        })
      );
    }
  }, [data]); // eslint-disable-line
  return <></>;
};

export default CSVTool;
