import { ProgressBar } from "primereact/progressbar";
import { useGetQueryData } from "../../hooks/queryData.hook";
import Table from "../common/table";
import { QueryDataColumns } from "../../utils/table.utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const QueryData = () => {
  const { loading, data, year, setYear } = useGetQueryData();

  return (
    <>
      {loading && (
        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year"]}
          label="Select Year"
          format="YYYY"
          value={dayjs(new Date(`01-01-${year}`))}
          maxDate={dayjs()}
          minDate={dayjs(new Date("01-01-2020"))}
          onChange={(e: any) => {
            setYear(e.$y);
          }}
          sx={{ marginRight: 2, marginTop: 2, marginBottom: 2 }}
        />
      </LocalizationProvider>
      <Table
        columns={QueryDataColumns}
        rows={data}
        onPageChange={() => {}}
        onRowPerPageChange={() => {}}
      ></Table>
    </>
  );
};

export default QueryData;
