import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";

function Table(props: {
  rows: any[];
  columns: any[];
  totalRows?: number;
  onPageChange: (page: number) => void;
  onRowPerPageChange: (currentRowsPerPage: number) => void;
}) {
  const [rowPerPage, setRowPerPage] = useState(10);
  const [firstPage, setFirstPage] = useState(0);

  return (
    <div style={{ border: "1px solid lightgray", borderRadius: "4px" }}>
      <DataTable
        value={props.rows}
        tableStyle={{ minWidth: "50rem" }}
        showGridlines
        stripedRows
      >
        {props.columns.map((elem, index) => (
          <Column
            key={index}
            field={elem.value}
            header={elem.name}
            sortable={elem.sortable}
          />
        ))}
      </DataTable>
      {props.totalRows && (
        <Paginator
          first={firstPage}
          totalRecords={props.totalRows}
          currentPageReportTemplate="{first}-{last} of {totalRecords}"
          template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          rows={rowPerPage}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageChange={({ rows, first }) => {
            setRowPerPage(rows);
            setFirstPage(first);
            props.onRowPerPageChange(rows);
            props.onPageChange(first);
          }}
        />
      )}
    </div>
  );
}

export default Table;
