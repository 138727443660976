/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGetReconData } from "../../hooks/recon.hook";
import { ReconColumns, ReconReducedColumns } from "../../utils/table.utils";
import { FilterMatchMode } from "primereact/api";
import dayjs from "dayjs";
import { CenterPickers } from "../common/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";

const ReconReport = () => {
  const { loading, date, data, commulativeData, setDate, setReload } =
    useGetReconData();
  const [currentPage, setCurrentPage] = useState(0);
  const [_data, setData] = useState(data.slice(0, 10));

  useEffect(() => {
    setData(data.slice(currentPage, currentPage + 10));
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(0);
    setData(data.slice(currentPage, currentPage + 10));
  }, [data]);

  const onFilter = (filters: any) => {
    setCurrentPage(0);
    if (filters.filters.order_id.constraints[0].value) {
      switch (filters.filters.order_id.constraints[0].matchMode) {
        case "startsWith": {
          setData(
            data
              .filter(
                (elem) =>
                  !filters.filters.order_id.constraints[0].value ||
                  elem.order_id.startsWith(
                    filters.filters.order_id.constraints[0].value
                  )
              )
              .slice(0, 10)
          );
          break;
        }
        case "contains": {
          setData(
            data
              .filter(
                (elem) =>
                  !filters.filters.order_id.constraints[0].value ||
                  elem.order_id.includes(
                    filters.filters.order_id.constraints[0].value
                  )
              )
              .slice(0, 10)
          );
          break;
        }
        case "notContains": {
          setData(
            data
              .filter(
                (elem) =>
                  !filters.filters.order_id.constraints[0].value ||
                  !elem.order_id.includes(
                    filters.filters.order_id.constraints[0].value
                  )
              )
              .slice(0, 10)
          );
          break;
        }
        case "endsWith": {
          setData(
            data
              .filter(
                (elem) =>
                  !filters.filters.order_id.constraints[0].value ||
                  elem.order_id.endsWith(
                    filters.filters.order_id.constraints[0].value
                  )
              )
              .slice(0, 10)
          );
          break;
        }
        case "equals": {
          setData(
            data
              .filter(
                (elem) =>
                  !filters.filters.order_id.constraints[0].value ||
                  elem.order_id ===
                    filters.filters.order_id.constraints[0].value
              )
              .slice(0, 10)
          );
          break;
        }
        case "notEquals": {
          setData(
            data
              .filter(
                (elem) =>
                  !filters.filters.order_id.constraints[0].value ||
                  elem.order_id !==
                    filters.filters.order_id.constraints[0].value
              )
              .slice(0, 10)
          );
          break;
        }
      }
    } else {
      setData(data.slice(0, 10));
    }
  };

  return (
    <>
      <div style={CenterPickers}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Date"
            format="DD/MM/YYYY"
            value={dayjs(date)}
            minDate={dayjs().add(-5, "years")}
            maxDate={dayjs().add(-1, "day")}
            onChange={(e: any) =>
              setDate(moment(e["$d"]).format("YYYY-MM-DD 00:00:00.0"))
            }
            sx={{ marginRight: 2, marginTop: 2, marginBottom: 2 }}
          />
        </LocalizationProvider>
        <Button
          icon="pi pi-refresh"
          loading={loading}
          className="mr-2"
          onClick={() => setReload(true)}
        />
      </div>
      {loading && (
        <ProgressBar mode="indeterminate" style={{ height: "4px" }} />
      )}
      <DataTable
        value={commulativeData}
        tableStyle={{ minWidth: "30rem" }}
        showGridlines
        stripedRows
        style={{ marginTop: "8px", marginBottom: "8px" }}
      >
        {ReconReducedColumns.map((elem, index) => (
          <Column
            key={index}
            field={elem.value}
            header={elem.name}
            body={(value) => (
              <span
                style={{
                  color:
                    elem.value === "status"
                      ? value.status === "RECONCILED"
                        ? "#0ca302"
                        : "#ad0303"
                      : "black",
                }}
              >
                {value[elem.value]}
              </span>
            )}
          />
        ))}
      </DataTable>
      <DataTable
        value={_data}
        tableStyle={{ minWidth: "30rem" }}
        showGridlines
        stripedRows
        style={{ marginTop: "8px", marginBottom: "8px" }}
        filterDisplay="menu"
        onFilter={(args) => {
          onFilter && onFilter(args);
        }}
        filters={{
          order_id: {
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
          } as any,
        }}
      >
        {ReconColumns.map((elem, index) => (
          <Column
            key={index}
            field={elem.value}
            header={elem.name}
            filter={elem.filter}
            filterPlaceholder={elem.filter_placeholder}
            filterClear={<></>}
          />
        ))}
      </DataTable>
      <Paginator
        first={currentPage}
        totalRecords={data.length}
        currentPageReportTemplate="{first}-{last} of {totalRecords}"
        template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        rows={10}
        onPageChange={(props) => {
          setCurrentPage(props.first);
        }}
      />
    </>
  );
};

export default ReconReport;
