import { gql } from "@apollo/client";

export const GET_SESSION_DATA = (startTime: string, endTime: string) => {
  return gql`
    query getSessionData {
      irctc_session_data(
        where: { date: { _lte: "${endTime}", _gte: "${startTime}" } }
      ) {
        date
        total_queries
        total_sessions
        average_session_time
        accuracy
        unique_users
      }
    }
  `;
};
