/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { apoloClient } from "../utils/apollo.utils";
import { bifurcateFootfall } from "../utils/table.utils";
import moment from "moment";
import {
  GET_FOOTFALL,
  GET_FOOTFALL_BOOKINGS,
  GET_FOOTFALL_PAYMENTS,
} from "../graphql/footfall.query";

const useGetOTPToBooking = () => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const getData = async () => {
    setLoading(true);
    const diff = moment(endTime).diff(moment(startTime), "days") + 1;

    for (let i = 0; i < diff; i++) {
      const date = moment(endTime)
        .add(-1 * i, "day")
        .format("YYYY-MM-DD");
      const footfall = await apoloClient.query({
        query: GET_FOOTFALL(date),
        fetchPolicy: reload ? "network-only" : "cache-first",
      });

      const result = bifurcateFootfall(footfall.data.irctc_footfall);

      const payments = await apoloClient.query({
        query: GET_FOOTFALL_PAYMENTS(date),
      });

      const bookings = await apoloClient.query({
        query: GET_FOOTFALL_BOOKINGS(date),
      });

      if (
        result["ade4a7db-d819-417d-832a-259307fd94c7,12"] &&
        result["ade4a7db-d819-417d-832a-259307fd94c7,13"]
      ) {
        const obj = {
          date,
          otp_sent: result["ade4a7db-d819-417d-832a-259307fd94c7,12"],
          otp_verified:
            result["ade4a7db-d819-417d-832a-259307fd94c7,13"] +
            ` (${(
              ((result["ade4a7db-d819-417d-832a-259307fd94c7,13"] -
                result["ade4a7db-d819-417d-832a-259307fd94c7,12"]) *
                100) /
              result["ade4a7db-d819-417d-832a-259307fd94c7,12"]
            ).toFixed(2)}%)`,
          payments:
            payments.data.irctc_payment_status_aggregate.aggregate.count +
            ` (${(
              ((payments.data.irctc_payment_status_aggregate.aggregate.count -
                result["ade4a7db-d819-417d-832a-259307fd94c7,13"]) *
                100) /
              result["ade4a7db-d819-417d-832a-259307fd94c7,13"]
            ).toFixed(2)}%)`,
          bookings:
            bookings.data.irctc_payment_status_aggregate.aggregate.count +
            ` (${(
              ((bookings.data.irctc_payment_status_aggregate.aggregate.count -
                payments.data.irctc_payment_status_aggregate.aggregate.count) *
                100) /
              payments.data.irctc_payment_status_aggregate.aggregate.count
            ).toFixed(2)}%)`,
        };

        setData((prev) => [...prev, obj]);
      }
    }
    setLoading(false);
    setReload(false);
  };

  useEffect(() => {
    setData(() => []);
    getData();
  }, [startTime, endTime]);

  useEffect(() => {
    if (reload) {
      setData(() => []);
      getData();
    }
  }, [reload]);

  return {
    data,
    loading,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    setReload,
  };
};

export { useGetOTPToBooking };
