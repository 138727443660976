import { useGetOTPToBooking } from "../../hooks/otpToBooking.hook";
import { OTPToBookingColumns } from "../../utils/table.utils";
import MultiPickerTable from "../common/multiPickerTable";

const OTPToBooking = () => {
  const {
    data,
    loading,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    setReload,
  } = useGetOTPToBooking();
  return (
    <MultiPickerTable
      data={data}
      loading={loading}
      startTime={startTime}
      endTime={endTime}
      setStartTime={setStartTime}
      setEndTime={setEndTime}
      columns={OTPToBookingColumns}
      setReload={setReload}
    />
  );
};

export default OTPToBooking;
