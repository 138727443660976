import { useMutation } from "@apollo/client";
import { useState } from "react";
import { LOGIN_MUTATION } from "../graphql/login.mutation";

const useLogin = () => {
  const [isLoggedIn] = useState(!!localStorage.getItem("token"));
  const [userType, setUserType] = useState(
    localStorage.getItem("user") ?? "USER"
  );
  const [onLogin] = useMutation(LOGIN_MUTATION);

  const setToken = (token: string, _userType?: string) => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", _userType ?? "USER");
    setUserType(_userType ?? "USER");
    window.location.reload();
  };

  return { isLoggedIn, userType, onLogin, setToken };
};

export default useLogin;
