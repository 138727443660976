import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      accessToken
      user_type
    }
  }
`;
