/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ParameterType } from "../types/parameters.types";
import moment from "moment";
import {
  AggregateKey,
  DataKey,
  QueryFunction,
  TableName,
} from "../utils/table.utils";
import { apoloClient } from "../utils/apollo.utils";

const useGetTableData = (
  tableName: TableName,
  parameters: ParameterType,
  setReload: Function
) => {
  const [tableData, setTableData] = useState({ count: 0, data: [] });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  const dataKey = DataKey[tableName];
  const aggregateKey = AggregateKey[tableName];

  const getData = async (reload = false) => {
    setLoading(true);
    const response = await apoloClient.query({
      query: QueryFunction[tableName](parameters),
      fetchPolicy: reload ? "network-only" : "cache-first",
    });

    setData(response.data);
    setLoading(false);
    setReload(false);
  };

  const parseData = (data: any) => {
    return data.map((elem: any) => {
      const bookingDate = elem.bookingDate
        ? moment(parseInt(elem.bookingDate))
            .add(-5.5, "hours")
            .format("YYYY-MM-DD hh:mm A")
        : undefined;
      const journeyDate = elem.journeyDate
        ? moment(parseInt(elem.journeyDate))
            .add(-5.5, "hours")
            .format("YYYY-MM-DD")
        : undefined;

      return {
        ...elem,
        bookingDate,
        journeyDate,
        PNR: elem.booking ? elem.booking.PNR : elem.PNR,
        payment_mode: elem.payments?.payment_mode ?? "",
        card_scheme: elem.payments?.card_scheme ?? "",
        txn_amount: elem.payments?.txn_amount ?? "",
      };
    });
  };

  useEffect(() => {
    if (data) {
      setTableData({
        data: parseData(data[dataKey]),
        count: data[aggregateKey].aggregate.count,
      });
    }
  }, [data]);

  useEffect(() => {
    getData();
  }, [parameters, tableName]);

  useEffect(() => {
    getData();
  }, []);

  return { tableData, loading, getData };
};

export { useGetTableData };
